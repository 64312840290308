import styled from "styled-components";

export const $BackArrow = styled.div`
  padding: 40px 20px 10px 20px;
  margin: auto;
  max-width: 1130px !important;
`;

export const $Title = styled.h1`
  text-align: center;
  background-color: var(--main-color);
  color: white;
  padding: 1.5rem 0rem;
  font-size: 30px;
  font-family: var(--primary-font-family);
`;

export const $SubTitle = styled.h5`
  font-family: var(--primary-font-family);
  text-align: center;
  font-size: 21px;
  font-weight: bold;
`;
