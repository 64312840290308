import { Information } from "../../components/Information/Information";
import { TopContent } from "../../components/TopContent/TopContent";
import { $Wrapper } from "../../styles";
function WindowCleaning() {
    return (
        <>
            <TopContent
                title="Fönsterputstning"
                subTitle="Vi är experter på att putsa fönster"
            />
            <$Wrapper>
                <Information
                    text={
                        <>
                            <p>
                                Du kan vara lugn för att vi har erfarenheten som krävs för
                                ett bra resultat, oavsett om det gäller en glasbeklädd
                                skyskrapa, stora entréfönster eller en enplansvilla.
                            </p>
                            <p>
                                Du väljer själv om du vill beställa fönsterputsning vid enstaka
                                tillfällen eller bestämd intervall.
                            </p>
                        </>
                    }
                />
            </$Wrapper>
        </>
    );
}
export default WindowCleaning;
