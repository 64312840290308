import styled from "styled-components";

export const $Button = styled.button`
  border: 2px solid white;
  border-radius: 5px;
  padding: ${({ $white }) => ($white ? "10px 30px" : "10px 60px")};
  background-color: var(--main-color);
  color:  white;
  font-size: 16px;
  white-space: nowrap;
  opacity: 100%;
  margin-top: ${({ $send }) => $send && "30px"};
  &:hover {
    opacity: 90%;
  }
`;
