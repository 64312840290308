import { useEffect } from "react";
import { Link } from "react-router-dom";
import { $Animation, $MainContent, $Image, $Content, $PolicyContent, $ButtonContent, $LeftContent } from "./styles";
import { Button } from "../../components/Button/Button";
import testing from "../../assets/images/4.jpg"
import homeImageOne from "../../assets/images/homeImageOne.jpg";
import homeImageTwo from "../../assets/images/homeImageTwo.jpg";
import AOS from "aos";
import 'aos/dist/aos.css'

function Home() {

    useEffect(() => {
        AOS.init({ duration: 1000 })

    }, [])
    return (
        <>
            <$MainContent>
                <$LeftContent>
                    <h4>Våra ledord</h4>
                    <$Animation>
                        <span>Ansvar</span><br />
                        <span>Samarbete</span><br />
                        <span>Arbetsglädje</span>
                    </$Animation>
                    <p>
                        Vi fäster stor vikt vid service, uppföljning, flexibilitet och
                        tillgänglighet för våra kunder.
                    </p>
                    <$ButtonContent>
                        <Link to="/kontakt">
                            <Button $white text="Kontakta oss"></Button>
                        </Link>
                        <Link to="/våra-tjänster" >
                            <Button $white text="Våra tjänster"></Button>
                        </Link>
                    </$ButtonContent>
                </$LeftContent>

                <$Image>
                    <img src={testing} alt="" />
                </$Image>

            </$MainContent>
            <$PolicyContent>
                <$Content data-aos="fade-up" data-aos-once="true"  >
                    <div>
                        <h2>Miljöpolicy</h2>
                        <p>
                            Vi utbildar personal för en miljömedvetenhet inom företaget genom att  kommunicera aktuella föreskrifter och branschkrav.
                        </p>

                    </div>

                    <img src={homeImageOne} width="500" alt="" />
                </$Content>
                <$Content $reverse data-aos="fade-up" data-aos-once="true"  >
                    <img src={homeImageTwo} width="500" alt="" />
                    <div>
                        <h2>Kvalitetspolicy</h2>
                        <p>
                            Vi arbetar långsiktigt för att säkerställa ett städresultat av högsta kvalitet.

                        </p>
                        <br />
                        <p> Detta åstadkommer vi genom att upprätthålla en nära kundrelation och aktivt arbeta med att följa upp våra uppsatta kvalitetsmål.</p>


                    </div>
                </$Content>
            </$PolicyContent>
        </>
    );
}

export default Home;
