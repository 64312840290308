import { TopContent } from "../../components/TopContent/TopContent";
import { $List } from "./styles"
import { $Wrapper, $UnderText } from "../../styles";

function OfficeCleaning() {
    return (
        <>
            <TopContent title="Kontorstädning" subTitle="Vad som ingår på kontorstädning" />
            <$Wrapper>
                <$List>
                    <li>Dammtorkning av åtkomliga ytor, armaturer och maskiner.</li>
                    <li>Dammsuga mattor och möbler.</li>
                    <li>Fuktmoppa golv.</li>
                    <li>Rengöring av toaletter, kök, cafeterior och personalrum.</li>
                    <li>Tömma papperskorgar och byta soppåsar.</li>
                    <li>Avfläcka glaspartier, dörrar och dörrkarma.</li>
                </$List>

                <$UnderText>
                    <p>
                        Vi utformar städningen efter era önskemål.
                    </p>
                </$UnderText>
            </$Wrapper>
        </>
    )
}

export default OfficeCleaning;
