import { $Card, $CardContent, $Text, $Link } from "./styles"

export const ServiceCard = ({ titel, text, img, link }) => {
    return (
        <$Card to={link}>
            <$CardContent>
                <img width="80px" src={img} alt="" />
                <h4> {titel} </h4>
                <$Text> {text} </$Text>
                <$Link to={link}> Läs mer </$Link>
            </$CardContent>
        </$Card>
    )
}