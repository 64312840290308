import { $ListContent } from "./styles";
import { $Wrapper, $ListName } from "../../styles"
import { TopContent } from "../../components/TopContent/TopContent"

function BigCleaning() {
    return (
        <>
            <TopContent title="Storstädning" subTitle="Exempel på vad som ingår i en normal storstädning" />
            <$Wrapper>
                <$ListContent>
                    <div>
                        <$ListName>BADRUM</$ListName>
                        <ul>
                            <li>Kranar skuras och avtorkas.</li>
                            <li>Kakelväggar rengörs.</li>
                            <li>Tvättställ rengörs ovan,under samt bakom.</li>
                            <li>Vattenledningar och rör avtorkas.</li>
                            <li>Toalettstol rengöres i, under samt bakom.</li>
                            <li>Golvbrunnar rengörs.</li>
                            <li>Badkar/duschkabin rengörs ovan, under samt bakom.</li>
                            <li>Speglar putsas.</li>
                            <li>Skåp torkas ut och invändigt.</li>
                            <li>Bastubänkar rengöres.</li>
                            <li>Golv skuras och moppas.</li>
                            <li>Lampor torkas av.</li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>
                            RUM
                        </$ListName>
                        <ul>
                            <li>Samtliga element rengörs, dammsugs bakom och emellan.</li>
                            <li>Väggar dammtorkas.</li>
                            <li>Garderober torkas ur.</li>
                            <li>Dörrar/handtag torkas av.</li>
                            <li>Väggkontakter och strömbrytare avtorkas.</li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>TVÄTTSTUGA</$ListName>
                        <ul>
                            <li>Tvättställ rengörs ovan, under samt bakom.</li>
                            <li>Kranar skuras och avtorkas.</li>
                            <li>
                                Tvättmaskiner avtorkas samt lösa delar diskas, filter rengörs.
                            </li>
                            <li>Skåp torkas ut och invändigt.</li>
                            <li>Golvbrunnar rengörs.</li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>KÖK</$ListName>
                        <ul>
                            <li>Spis och ugn torkas av ovan, bakom och under.</li>
                            <li>Kylskåp och frys rengörs ovan/sidor samt under.</li>
                            <li>Skåp/lådor samt luckor torkas in och utvändigt.</li>
                            <li>Bänkar och skärbrädor avtorkas.</li>
                            <li>Diskmaskin avtorkas utvändigt.</li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>
                            FÖNSTER
                        </$ListName>
                        <ul>
                            <li>Samtliga fönster tvättas på alla sidor.</li>
                            <li>Fönsterbågar och snickerier avtorkas..</li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>
                            GOLV
                        </$ListName>
                        <ul>
                            <li>Samtliga golv dammsugs.</li>
                            <li>Golvlister, dörrkarmar och trösklar avtorkas.</li>
                            <li>Golv våttorkas.</li>
                        </ul>
                    </div>
                </$ListContent>
            </$Wrapper>
        </>
    );
}

export default BigCleaning;
