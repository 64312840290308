import styled from "styled-components";

export const $List = styled.ul`
   display: flex;
   flex-direction: column;
   gap: 10px;
    list-style-type: square;
    margin-bottom: 40px;
  li {
    font-size: 18px;
    margin-left: 15px;
  }
`;

