import { $Footer, $NavMenu, $Link, $Line, $Content } from "./styles";
import { BiCopyright } from "react-icons/bi";
import Logo from "../../assets/svg/logo.svg";

export const Footer = () => {
    return (
        <$Footer>
            <$Content>
                <img src={Logo} width="250" alt="" />

                <$NavMenu>
                    <h3>Meny</h3>
                    <li>
                        <$Link to="/">Hem</$Link>
                    </li>
                    <li>
                        <$Link to="/våra-tjänster">Våra tjänster</$Link>
                    </li>
                    <li>
                        <$Link to="/om-oss">Om oss</$Link>
                    </li>
                    <li>
                        <$Link to="/kontakt">Kontakt</$Link>
                    </li>
                </$NavMenu>

                <$NavMenu>
                    <h3>Kontakta oss</h3>
                    <li>
                        <span>Ring oss</span>0704700710
                    </li>
                    <li>
                        <span>Epost</span>info@etc-cleaning.com
                    </li>
                    <li>
                        <span>Huvud kontor</span>Vagnmakaregatan 9, 415 72 Göteborg
                    </li>
                </$NavMenu>
            </$Content>
            <$Line></$Line>
            <div>
                <BiCopyright /> 2023 ETC Cleaning AB - All Rights Reserved
            </div>
        </$Footer>
    );
};
