import { ServiceCard } from "../../components/ServiceCard/ServiceCard";
import { $GridWrapper, $Text } from "./styles";
import { $Title } from "../../styles";
import house from "../../assets/svg/house.svg";
import bigClean from "../../assets/svg/bigClean.svg";
import moving from "../../assets/svg/moving.svg";
import office from "../../assets/svg/office.svg";
import floor from "../../assets/svg/floor.svg";
import window from "../../assets/svg/window.svg";
import industri from "../../assets/svg/industri.svg";

function Services() {
    return (
        <>
            <$Title>Våra tjänster</$Title>
            <$Text>    ETC Cleaning AB erbjuder skräddarsydda lösningar som lyfter bort
                bördan av städning, så att du kan njuta av en fräsch och välkomnande
                miljö utan bekymmer.</$Text>

            <$GridWrapper>
                <ServiceCard
                    link="/hemstädning"
                    titel="Hemstädning"
                    img={house}
                    text="Vi levererar skräddarsydd hemstädning utan bindningstid. Du väljer själv vad som ska ingå."
                />

                <ServiceCard
                    link="/storstädning"
                    titel="Storstädning"
                    img={bigClean}
                    text="En storstädning tar hand om alla områden vanlig städning inte klarar av att ta bort och gör ditt hem skinande rent igen."
                />
                <ServiceCard
                    link="/flyttstädning"
                    titel="Flyttstädning"
                    img={moving}
                    text="Vi har lång erfarenhet av flyttstädning och vet vad som krävs för ett godkänt resultatet i samband med överlämningen."
                />
                <ServiceCard
                    titel="Kontorstädning"
                    link="/kontorstädning"
                    img={office}
                    text="Ett kontor som är välstädat gör att medarbetare och besökare känner förtroende för dig och ditt företag."
                />
                <ServiceCard
                    titel="Industristädning"
                    link="/industristädning"
                    img={industri}
                    text="Varje industrimiljö är unik, med speciella förutsättningar. Det innebär att varje uppdrag blir unikt helt och hållet och utformat efter behoven." />
                <ServiceCard
                    titel="Golvvård"
                    link="/golvvård"
                    img={floor}
                    text="Vi har kunskapen att väcka liv i golv som sedan länge tappat lystern. Låt inte slitna ytskikt grusa dina affärer."
                />
                <ServiceCard
                    titel="Fönsterputsning"
                    link="/fönsterputsning"
                    img={window}
                    text="Du väljer själv om du vill beställa fönsterputsning vid enstaka tillfällen eller bestämd intervall."
                />
            </$GridWrapper>
        </>
    );
}

export default Services;
