import styled from "styled-components";

export const $ListContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 1130px !important;
  div {
    flex: 1 0 27%;
    margin: 1%;
  }

  @media screen and (max-width: 1024px) {
    div {
      flex: 1 0 37%;
    }
  }

  @media screen and (max-width: 850px) {
    div {
      flex: 1 0 100%;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: square;

    li {
      font-size: 18px;
      margin-left: 15px;
      max-width: 700px;
    }
  }
`;
