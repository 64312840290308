import { $Div } from "./styles";
import { $UnderText } from "../../styles";

export const Information = ({ text }) => {
    return (
        <>
            <$Div>
                <$UnderText $margin>{text}</$UnderText>
            </$Div>
        </>
    );
};
