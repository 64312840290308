import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const $Wrapper = styled.section`
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
`

export const $Navigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
 padding: 0.5rem 3rem;
  background-color: var(--secondary-color);
`;

export const $NavList = styled.ul`
  list-style: none;
  display:flex;
  @media (max-width: 1024px) {
    display: ${({ open }) => !open && "none"};
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 10rem;
    width: 100%;
    height: 100vh;
  }

   @media (min-width: 1025px) {
     padding: 1rem 2rem;
  }

  li {
    font-size: var(--title);
    font-family:  var( --primary-font-family);
    text-transform:uppercase;
  }
`;

export const $NavLink = styled(NavLink)`
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  color: var(--black-color);
  &:hover {
    background-color: var(--main-color);
    color: var(--secondary-color);
  }
  
  @media (min-width: 1025px) {
    &.active {
      background-color: var(--main-color);
      color: var(--secondary-color);
    }
  }
`;

export const $NavButton = styled.button`
  visibility: hidden;
  background: transparent;
  border: none;
  color: var(--main-color);
  @media (max-width: 1024px) {
    visibility: visible;
    z-index: 1;
  }
`;
