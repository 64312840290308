import { $AboutContent, $AboutText, $MoreAbout, $LastText, $Text } from "./styles";
import test from "../../assets/images/2.jpg"
import { $Title } from "../../styles";

function About() {

    return (
        <>
            <$Title>Om oss</$Title>

            <$AboutContent>
                <div>
                    <img src={test} width="480" alt="" />
                </div>

                <$AboutText>
                    <div>
                        <h2>Vi erbjuder personlig service med omtanke</h2>
                    </div>
                    <p>
                        <span>ETC Cleaning AB</span> är ett städföretag med säte i Göteborg.
                        Vår kärnverksamhet är regelbunden städning, men förutom daglig
                        lokalvård så erbjuder vi andra tjänster som tex. golvvård,
                        storstädning och fönsterputs. Företagets geografiska marknad är
                        Västra Götaland. Våra kunder utgörs av företag, skolor,
                        privatpersoner, kontor och offentlig sektor.
                    </p>
                </$AboutText>
            </$AboutContent>

            <$MoreAbout>
                <div>
                    <h3>Teknisk kapacitet</h3>
                    <p>
                        Vi utför daglig städning på ca 10 000 kvm runt om i Västra Götaland.
                        Vi har egna avdelningar för golvvård och fönsterputsning. Vi utför
                        storstädningsarbete av alla de slag, vilket kan innefatta arbete på
                        ställningar och/eller med skylift. Med vår maskinpark kan vi utföra
                        flera kombinerade uppdrag samtidigt
                    </p>
                </div>
                <$Text>Yrkesmässig kapacitet</$Text>
                <$LastText>
                    <div>
                        <h6>Personal</h6>
                        <p>
                            Våra anställda har grundutbildning i städ samt hygienutbildning Vi
                            arbetar kontinuerligt med att utbilda vår personal i kvalitet och
                            miljö
                        </p>
                    </div>
                    <div>
                        <h6>Kvalitet</h6>
                        <p>
                            Vi bygger och dokumenterar ett integrerat kvalitets- och
                            miljösystem helt enligt kraven för ISO 9001:2015 och ISO
                            14001:2015 Att vara ett miljömedvetet företag är viktigt för oss
                        </p>
                    </div>
                </$LastText>
            </$MoreAbout>
        </>
    );
}

export default About;
