import { Information } from "../../components/Information/Information";
import { TopContent } from "../../components/TopContent/TopContent";
import { $Wrapper } from "../../styles";

function FloorCare() {
    return (
        <>
            <TopContent title="Golvvård" subTitle="Vilka typer av golv gör vi golvvård på?" />
            <$Wrapper>
                <Information
                    title="Golvvård"
                    subTitle="Vilka typer av golv gör vi golvvård på"
                    text="Trä, plast, laminat, sten eller textil spelar egentligen ingen roll. Vi har kunskapen att väcka liv i golv som sedan länge tappat lystern. Låt inte slitna ytskikt grusa dina affärer."
                />
            </$Wrapper>
        </>
    );
}
export default FloorCare;
