import { $Wrapper } from "../../styles"
import { TopContent } from "../../components/TopContent/TopContent";
import { Information } from "../../components/Information/Information";
function IndustrialCleaning() {
    return (
        <>
            <TopContent title="Industristädning" subTitle="Professionell industri/höghöjdsstädning" />
            <$Wrapper>
                <Information text={<>
                    <p>Varje industrimiljö är unik, med speciella förutsättningar. Det innebär också att varje uppdrag blir unikt, helt och hållet utformat efter behoven. Vi på ETC Cleaning AB har många års erfarenhet av städning i alla tänkbara branscher, från hårt nedsmutsade verkstadsmiljöer till livsmedelsindustrier med extra höga hygienkrav.</p>
                    <p>Hör av dig, så får vi diskutera hur vi kan möta just dina behov!</p>
                </>
                } />
            </$Wrapper>
        </>
    )
}

export default IndustrialCleaning;