import { TopContent } from "../../components/TopContent/TopContent"
import { $ListContent } from "./styles";
import { $Wrapper, $ListName, $UnderText } from "../../styles";

function HomeCleaning() {
    return (
        <>
            <TopContent title="Hemstädning" subTitle="Exempel på vad som kan ingå i hemstädning" />
            <$Wrapper>
                <$ListContent>
                    <div>
                        <$ListName>
                            KÖK
                        </$ListName>
                        <ul>
                            <li>
                                Avtorkning av diskbänk, arbetsbänk, kranar samt rengöring av
                                diskho.
                            </li>
                            <li>Avtorkning av kakel ovanför diskbänk/arbetsbänk/spis.</li>
                            <li>Avtorkning av vitvaror/hushållsmaskiner utvändigt.</li>
                            <li>
                                Avfläckning av skåpsluckor utvändig samt front till lådor och
                                handtag.
                            </li>
                            <li> Avtorkning av socklar vid golvet på underskåp.</li>
                            <li>Tömning och avtorkning i och runt sopbehållare.</li>
                            <li>Avtorkning av spis och ugn utvändigt.</li>
                            <li>
                                Rengöring av micro in- och utvändigt. Avtorkning av spiskåpa
                                utsida (ej inuti).
                            </li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>
                            BADRUM
                        </$ListName>
                        <ul>
                            <li>Avtorkning/rengöring med fuktig svamp/trasa av badkar.</li>
                            <li>
                                Avtorkning av skåpsluckor utvändigt samt front till lådor och
                                handtag.
                            </li>
                            <li>
                                Avtorkning/rengöring av duschkabin och i duschutrymme. Avtorkning
                                av golvbrunn utvändigt.
                            </li>
                            <li>
                                Avtorkning av kakel ovanför/under handfat och ovanför badkar.
                            </li>
                            <li>
                                Avtorkning av kranar/blandare, duschslang/duschmunstycke (ej
                                vattenlås).
                            </li>
                            <li>Avtorkning av vågräta VVS-rör (högst 1,8 m).</li>
                            <li>
                                Rengöring av toalettstol i och utanpå. Rengöring av handfat
                                inklusive avtorkning av undersida. Avtorkning av krokar,
                                handdukshängare/värmare.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <$ListName>
                            ÖVRIGA STÄDMOMENT
                        </$ListName>
                        <ul>
                            <li>Avtorkning med torr trasa fast belysning, högst 1,8 m.</li>
                            <li>Avtorkning med torr trasa av eluttag, strömbrytare.</li>
                            <li>
                                Avfläckning av dörr och dörrkarmar, avtorkning av dörrhandtag.
                            </li>
                            <li>
                                Avtorkning insida av entrédörren. Avfläckning av inglasade dörrar,
                                inklusive putsning av fönsterdel upp till 1,8 m.
                            </li>
                            <li>Damning av element.</li>
                            <li>Avtorkning av alla fönsterbrädor.</li>
                            <li>Avfläckning av garderobsdörrar utsida.</li>
                            <li>Dammsugning av spår garderobsdörrar.</li>
                            <li>Dammsugning av golv, trösklar samt mattor.</li>
                            <li>Våttorkning av golv.</li>

                            <li>Notera att vi inte flyttar tyngre möbler.</li>
                            <li>Dammsugning av golvlister inkl fläckborttagning.</li>
                            <li>Dammsugning av klädda/stoppade möbler.</li>
                            <li>
                                Avtorkning med torr trasa av fria horisontella ytor på bord,
                                stolar, skåp och hyllor Avfläckning med fuktig trasa vid behov
                                (högst 1,8 m).
                            </li>
                            <li>Tömning av papperskorgar i alla rum.</li>
                            <li> Putsning av speglar.</li>
                            <li> Damning av tavlor.</li>
                            <li> Avtorkning av ledstänger och trappräcken. </li>
                            <li> Dammsugning samt våttorkning av trappsteg.</li>
                            <li> Avtorkning utvändigt av torkskåp/tumlare och tvättmaskin.</li>
                        </ul>
                    </div>

                    <div>
                        <$ListName>MÖJLIGA TILLVALL</$ListName>
                        <ul>
                            <li>Vattna blommor.</li>
                            <li>Ugnsrengöring.</li>
                            <li>Bädda rent säng.</li>
                            <li>Strykning.</li>
                        </ul>
                    </div>
                </$ListContent>

                <$UnderText>
                    <p>
                        Vi administrerar din skattereduktion direkt med Skatteverket och du betalar endast 50 procent av arbetskostnaden på din faktura. Max tak som du personligen kan utnyttja per år, är 75 000 kronor.
                        Gå in på Skatteverkets hemsida om du vill veta mer om rutavdraget. Du som kund har ansvar för att det blir rätt i slutändan.
                    </p>
                </$UnderText>
            </$Wrapper>
        </>
    );
}

export default HomeCleaning;
