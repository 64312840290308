import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Navigation } from "./components/Navigation/Navigation";
import { Footer } from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import HomeCleaning from "./pages/HomeCleaning/HomeCleaning";
import BigCleaning from "./pages/BigCleaning/BigCleaning";
import MoveCleaning from "./pages/MoveCleaning/MoveCleaning";
import OfficeCleaning from "./pages/OfficeCleaning/OfficeCleaning";
import IndustrialCleaning from "./pages/IndustrialCleaning/IndustrialCleaning";
import FloorCare from "./pages/FloorCare/FloorCare";
import WindowCleaning from "./pages/WindowCleaning/WindowCleaning";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navigation />
      <div className="content-area">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/våra-tjänster" element={<Services />} />
          <Route path="/om-oss" element={<About />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/hemstädning" element={<HomeCleaning />} />
          <Route path="/storstädning" element={<BigCleaning />} />
          <Route path="/flyttstädning" element={<MoveCleaning />} />
          <Route path="/kontorstädning" element={<OfficeCleaning />} />
          <Route path="/industristädning" element={<IndustrialCleaning />} />
          <Route path="/golvvård" element={<FloorCare />} />
          <Route path="/fönsterputsning" element={<WindowCleaning />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
