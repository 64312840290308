import styled from "styled-components";

export const $Text = styled.h5`
  font-family: var(--primary-font-family);
  text-align: center;
  font-size: 20px;
  padding: 3rem 2rem;
  max-width: 900px;
  margin: 0 auto;
`;

export const $GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  justify-content: center;
  grid-gap: 20px;
`;
