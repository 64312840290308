import { useState } from "react";
import { $Wrapper, $Navigation, $NavButton, $NavList, $NavLink } from "./styles";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import { TopBar } from "../TopBar/TopBar";

export const Navigation = () => {
    const [open, setOpen] = useState(false);
    return (
        <$Wrapper>
            <TopBar />
            <$Navigation>
                <img src={logo} width="90"></img>
                <$NavButton onClick={() => setOpen(!open)}>
                    {open ? <FaTimes size={35} /> : <FaBars size={35} />}
                </$NavButton>
                <$NavList open={open}>
                    <li onClick={() => setOpen(false)}>
                        <$NavLink to="/">Hem</$NavLink>
                    </li>
                    <li onClick={() => setOpen(false)}>
                        <$NavLink to="/våra-tjänster">Våra Tjänster</$NavLink>
                    </li>
                    <li onClick={() => setOpen(false)}>
                        <$NavLink to="/om-oss">Om Oss</$NavLink>
                    </li>
                    <li onClick={() => setOpen(false)}>
                        <$NavLink to="/kontakt">Kontakt</$NavLink>
                    </li>
                </$NavList>
            </$Navigation>
        </$Wrapper>
    );
};
