import { TopContent } from "../../components/TopContent/TopContent";
import { $List } from "./styles";
import { $Wrapper, $UnderText } from "../../styles";

function MoveCleaning() {
    return (
        <>
            <TopContent
                title="Flyttstädning"
                subTitle="Checklista på vad som normalt ingår i flyttstädning"
            />
            <$Wrapper>
                <$List>
                    <li>Dammsuga och torka golv</li>
                    <li>Damma av tak och väggar</li>
                    <li>Rengöra skåp, garderober och hyllor</li>
                    <li>Torka av spis och diskbänk</li>
                    <li>Djuprengöra ugn, vitvaror och mikrovågsugn</li>
                    <li>Rengöra handfat, toalett, dusch, badkar och ytor i badrum</li>
                    <li>Rensa avlopp och avloppsbrunn</li>
                    <li>Torka av lister, dörrar och trösklar</li>
                    <li>Rengöra vägguttag och strömbrytare</li>
                    <li>Rengöra element, fönsterbänkar och synliga ventiler</li>
                </$List>

                <$UnderText $margin>
                    <h3>Vad ingår inte i priset för flyttstädning?</h3>
                    <p>
                        Däremot finns det vissa saker som inte ingår i flyttstädningen, men
                        som du ändå behöver fixa när du ska flytta ut. Städfirman rengör
                        inga ytor utomhus och utför inte trädgårdsstädning. Städfirman tar
                        inte heller bort spikar och krokar från väggar, tvättar inte tak och
                        väggar och kör inte bort sopor eller återvinning.
                    </p>
                </$UnderText>
            </$Wrapper>
        </>
    );
}

export default MoveCleaning;
