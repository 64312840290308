import styled from "styled-components";

export const $Wrapper = styled.div`
  padding: 40px 30px 0px 30px;
  margin: auto;
  max-width: 1130px !important;
`

export const $ListName = styled.h3`
  margin-bottom: 10px;
  font-family: var(--primary-font-family);
  color: var(--main-color);
`;

export const $Title = styled.h1`
  text-align: center;
  background-color: var(--main-color);
  color: white;
  padding: 1.5rem 0rem;
  font-size: 30px;
  font-family: var(--primary-font-family);
`;

export const $UnderText = styled.div`
font-size: 19px;
    font-family: var(--primary-font-family);

  p {
    margin-top: ${({ $margin }) => $margin ? "10px" : "40px"}}
  }
  h3{
  font-size: 20px;

  }
`;
