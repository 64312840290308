import styled from "styled-components";

export const $ListContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  div {
    flex: 1 0 47%; 
    margin: 1%; 
  }

  @media screen and (max-width: 1024px) {
    gap: 20px;
    div {
      flex: 1 0 100%;
    }
  }

  ul {
      display: flex;
   flex-direction: column;
   gap: 10px;
    list-style-type: square;

    li {
      font-size: 18px;
      margin-left: 15px;
      max-width: 700px;
    }
  }
`;
