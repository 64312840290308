import styled from "styled-components";

export const $Input = styled.input`
font-size: 16px;
  width: 100%;
  outline: none;
  padding: 13px 20px;
  border: 2px solid #b9b9b9;
  border-radius: 5px;
`;
