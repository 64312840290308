import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const $Footer = styled.div`
font-family: var(--primary-font-family);
background-color: var(--main-color);
margin-top: 7rem;
color: var(--secondary-color);
    padding: 40px;
    div:last-child{
        text-align: center;
        margin-top: 5px;
       color: var(--grey-color);
    }
  }
`;

export const $NavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  span {
    color: var(--secondary-color);
  }

  li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--grey-color);
  }
`;

export const $Link = styled(NavLink)`
text-decoration: none;
color: var(--grey-color);
         &:hover{
        color: white;

`;

export const $Line = styled.div`
  background: var(--grey-color);
  height: 1px;
  margin-top: 30px;
  width: 100%;
`;

export const $Content = styled.div`
  padding: 2rem 0rem;
  @media (min-width: 1010px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 7rem;
  }

  img {
    margin-top: -20px;
    margin-bottom: 2rem;
  }

  ul {
    margin-bottom: 2rem;
  }
`;
