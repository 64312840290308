import styled, { keyframes } from "styled-components";

export const $MainContent = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  font-family: var(--primary-font-family);
  max-width: 1300px;
  margin: auto;
  padding: 7rem 3rem;
  h4 {
    font-size: 25px;
  }
  @media (max-width: 1030px) {
    flex-direction: column;
    padding: 3rem 1rem;
    gap: 30px;
  }
`;

export const $Image = styled.div`
flex: 2;
`


export const $LeftContent = styled.div`
flex: 2;
  p {
    font-size: 25px;
    margin: 16px 0px 30px 0px;
    font-weight: bold;
  }

  @media (max-width: 1030px) {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      text-align: center;
      font-size: 23px;
    }
  }
`;
const fade = keyframes`
0%{top: 0;}
25%{top: -50px}
50%{top: -100px}
75%{top: -50px}
100%{top: 0}  
  
`;

export const $Animation = styled.div`
  overflow: hidden;
  height: 50px;
  span {
    position: relative;
    font-size: 40px;
    animation: ${fade} 12s infinite;
    color: var(--main-color);
    font-weight: bold;
  }
  @media (max-width: 1030px) {
    text-align: center;
  }
`;

export const $ButtonContent = styled.div`
  display: flex;
  gap: 10px;
`;

export const $PolicyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9rem;
  padding: 0px 30px;
  p {
    font-family: var(--primary-font-family);
    font-size: 18px;
    line-height: 1.5;
  }
  > div:first-child {
    margin-bottom: 13rem;
  }

  @media (max-width: 1030px) {
    margin-top: 6rem;

     > div:first-child {
    margin-bottom: 5rem;
  }
  }
`;

export const $Content = styled.div`
  display: flex;
  max-width: 1040px;
  gap: 4rem;
  align-items: center;
  h2 {
    font-family: var(--primary-font-family);
    color: var(--main-color);
    padding-bottom: 1rem;
    font-size: 28px;
  }

  @media (max-width: 1030px) {
    flex-wrap: ${({ $reverse }) => ($reverse ? "wrap-reverse" : "wrap")};
    img {
      width: 100%;
    }
  }
`;
