import styled from "styled-components";
import { Link } from "react-router-dom";

export const $CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--main-color);
  padding: 35px;
  height: 367px;
  &:hover {
    background: #032c81;
  }

  h4 {
    font-family: var(--primary-font-family);
    font-size: 20px;
    color: #fff;
  }
`;

export const $Card = styled(Link)`
  text-decoration: none;
`


export const $Link = styled(Link)`
  align-self: flex-end;
  margin-top: auto;
  color: black;
  font-size: 16px;
  background-color: white;
  padding: 10px;
  text-decoration: none;
  font-family: sans-serif;
`;

export const $Text = styled.div`
  font-size: 17px;
  line-height: 1.5;
  font-family: var(--text-font);
  color: #fff;
`;
