import { useRef } from "react";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { $Title } from "../../styles";
import emailjs from "@emailjs/browser"
import { Toaster, toast } from 'sonner';

function Contact() {
    const form = useRef()
    const sendMessage = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_t5ef0oy', 'template_0fe7h45', form.current, 'G-kRjv62qtUQSoRaj').then((result) => {
            toast("Tack för meddelandet! Vi återkommer snart");
        }, (error) => {
            toast("Gick inte att skicka meddelandet. Försök igen!");
        })
        e.target.reset();
    }


    return (
        <>
            <Toaster position="top-center" />
            <$Title>Kontakta oss</$Title>
            <form className="form" ref={form} onSubmit={sendMessage} >
                <h4 className="rubrik">Vad behöver ditt företag?</h4>
                <p className="text">
                    Vi utför alla typer av städning.     Vi har ett brett sortiment av egna
                    tjänster och ett stort nätverk som låter oss skräddarsy vad som
                    behövs.
                </p>

                <div className="input-content">
                    <label>Namn *</label>
                    <Input type="text" text="Namn*" name="name" />
                </div>

                <div className="input-content">
                    <label>E-post *</label>
                    <Input type="text" text="E-post*" name="email" />
                </div>

                <div className="input-content">
                    <label>Mobil nummer *</label>
                    <Input type="text" text="nummer" name="number" />
                </div>
                <div className="input-content">
                    <label>Tjänster *</label>
                    <select name="service" id="välj tjänst" required>
                        <option value=""  >
                            Välj tjänst
                        </option>
                        <option value="Kontorsstädning">Kontorsstädning</option>
                        <option value="Butiksstädning">Butiksstädning</option>
                        <option value="Fönsterputsning">Fönsterputsning</option>
                        <option value="Byggstädning">Byggstädning</option>
                        <option value="Industristädning">Industristädning</option>
                        <option value="Hemstädning">Hemstädning</option>
                        <option value="Flyttstädning">Flyttstädning</option>
                        <option value="Golvvård">Golvvård</option>
                        <option value="Sanering">Sanering</option>
                        <option value="Annat">Annat</option>
                    </select>
                </div>
                <div className="input-content">
                    <label>Meddelande *</label>
                    <textarea rows={10} required name="message"></textarea>
                </div>
                <Button $send className="send-btn" text="SKICKA" />
            </form>
        </>
    );
}

export default Contact;
