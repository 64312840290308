import styled from "styled-components";

export const $Div = styled.div`
  display: flex;
  background-color: var(--main-color);
  gap: 30px;
  padding: 7px 20px;
  justify-content: flex-end;
  span {
    display: inline-flex;
    align-items: center;
    color: white;
    gap: 3px;
    text-transform: uppercase;
    font-size: var(--sub-title);
  }

  @media (max-width: 1024px) {
    span{
   font-size: 12px;
    }

  }
`;
