import { $Div } from "./styles"
import mail from "../../assets/svg/mail.svg"
import phone from "../../assets/svg/phone.svg"

export const TopBar = () => {
    return (
        <$Div>
            <span>  <img src={phone} alt="" /> 0704700710</span>
            <span>  <img src={mail} alt="" /> info@etc-cleaning.com</span>
        </$Div>
    )
}