import styled from "styled-components";

export const $AboutContent = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
  padding: 4rem 2rem;
  max-width: 1200px;

  img {
    border-radius: 10px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 2rem;

    h2 {
      font-size: 25px;
    }
    img{
      width: 100%;
    }
  }
`;

export const $AboutText = styled.div`
  font-family: var(--primary-font-family);
  flex: 2;

  h2 {
    margin-bottom: 20px;
    font-style: italic;
    font-size: 25px;
  }
  p {
    line-height: 1.5;
    font-size: 20px;
    span {
      font-style: italic;
    }
  }
`;

export const $MoreAbout = styled.div`
  font-family: var(--primary-font-family);
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  max-width: 1200px;
  padding: 30px;

  h3 {
    font-size: 25px;
    margin-bottom: 20px;
    color: var(--main-color);
    font-style: italic;
  }

  p {
    font-size: 18px;

    line-height: 1.5;
  }

  h6 {
    font-size: 19px;
    margin-bottom: 5px;
  }
`;

export const $LastText = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  p {
    max-width: 700px;
  }
`;

export const $Text = styled.h3`
  margin-top: 4rem;
`;
