import { Link } from "react-router-dom";
import { $BackArrow, $SubTitle } from "./styles";
import { $Title } from "../../styles"
import arrow from "../../assets/svg/arrow.svg";

export const TopContent = ({ title, subTitle }) => {
    return (
        <>
            <$Title> {title} </$Title>
            <$BackArrow>
                <Link to="/våra-tjänster">
                    <img src={arrow} width="40px" />
                </Link>
            </$BackArrow>
            <$SubTitle>{subTitle}</$SubTitle>
        </>
    );
};
